import axios from 'axios';

import { GET_USER, LOGIN_ACTION, AUTHENTICATE_ACTION, LOGOUT_ACTION } from '../constants';

const LoginHeader = {'e-id': 'collections'};

export const getUser = (user) =>({
    type: GET_USER,
    user
});

export const loginAction = (data) => ({
    type: LOGIN_ACTION,
    data
});

export const authenticateAction = (auth) => ({
    type: AUTHENTICATE_ACTION,
    auth
})

export const logoutAction = (data) => ({
    type: LOGOUT_ACTION,
    data
});
  
export const loginActionApi = (data) => dispatch => 
    axios.post('/login', data, {headers:LoginHeader})
    .then(res => res)
    .then(resData => dispatch(loginAction(resData)));

export const authenticateApi = (headers) => dispatch =>
    axios.get('/authenticate?appId=Collect', {headers})
    .then(res => res)
    .then(resData => dispatch(authenticateAction(resData)))

export const logoutActionApi = (headers) => dispatch => 
    axios.post('/auth/logout', '', {headers})
    .then(res => res)
    .then(resData => dispatch(logoutAction(resData)));

export const userFetch = (data) => dispatch => 
    dispatch(getUser(data));