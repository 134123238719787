export const environment = {
    production: true,
    currencySign: 'USD', // to update currency code, pls refer 'https://transferwise.com/gb/blog/world-currency-symbols'
    currencyFormat: 'en-IN',
    uiDateFormat: 'DD-MM-YYYY', // date format to display in UI
    apiDateFormat: 'YYYY-MM-DD', // date format to send in req
    callBackFormat: 'YYYY-MM-DD HH:mm',
    e_id: 'Customer_flow',
    loginUrl: 'https://auth.epikindifi.com:8443/auth/realms/Customer_flow/protocol/openid-connect/auth?client_id=customer&response_type=code&state=fj8o3n7bdy1op5&redirect_uri=https://fq3-analyseportal.epikindifi.com',
    redirectUri: 'https://fq3-analyseportal.epikindifi.com',
    baseUrl: 'https://fq3.epikindifi.com/consumerAPI/v3/auth'
  };
