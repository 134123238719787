import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LayoutComponent from '../components/layout';
import Landing from '../components/pages/landing';
import DashBoard from '../components/pages/dashboard';
import Listing from '../components/pages/listing';

function Routers() {
    return (
        <>
            <Router>
                <LayoutComponent>
                    <Switch>
                        <Route exact path="/" component={Landing} />
                        <Route path="/dashboard" component={DashBoard} />
                        <Route path="/listing" component={Listing} />
                        {/* <Route component={PageNotFound} /> */}
                    </Switch>
                </LayoutComponent>
            </Router>
        </>
    )
}

export default Routers
