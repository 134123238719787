import React,{ useState } from 'react';
import { Provider } from 'react-redux';
import store from './store';
import Routers from './routers';
import { Loader, Toaster } from '../src/components/generic';
import ContextProvider from '../src/context';

function App() {
  return (
    <>
        <ContextProvider>      
          <Loader/>
          <Toaster/>
          <Provider store={store}>
            <Routers />
          </Provider>
        </ContextProvider>
    </>
  )
}

export default App
