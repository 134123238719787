import React, { useState } from 'react';
import { map } from 'lodash';
import './style.scss';

export default function Search(props) {
  const [showSearch, setShowSearch] = useState(false);
  const [showSearchOption, setSearchOption] = useState(false);
  const [searchList, setSearchList] = useState([]);

  const { onChange } = props;

  const handleSearch = (e) => { console.log('search'); e.stopPropagation(); };
  const handleSearchShow = () => {
    setShowSearch(!showSearch)
    setSearchOption(!showSearchOption);
    search();
  }

  function search() {
    setSearchList(props.header);
  }
  return (
    <>
      {/* <div className="search-wrap">
            <input type="text" onChange={onChange} />
            <span><i className="fa fa-search" aria-hidden="true"></i></span>
        </div> */}
      <div className="search-row" onClick={(e) => { handleSearch(e) }}>
        <div className="search-row__icon"><i className="fa fa-search" aria-hidden="true"></i></div>
        <ezee-chips-tag secondary close ></ezee-chips-tag>
        {!showSearch && <div className="search-row__show" onClick={() => { handleSearchShow() }}></div>}
        <input type="text" className="search-row__input" placeholder="search" onChange={onChange} />
        {showSearchOption && <div className="search-row__result scroll-bar">
          {showSearchOption}
          <div className="search-row__selected">
            <span className="search-row__reset">Reset</span>
          </div>
          <ul className="search-row__list">
            {map(searchList, (e) => <li className="search-row__item">{e.value}</li>)}
            <li>No Records Found!</li>
          </ul>
        </div>}
      </div>
    </>
  );
}

Search.defaultProps = {
  defaultValue: "",
  onChange: (event) => { console.log(event) },
}
