import { LOGOUT_ACTION } from '../constants';

const logoutFetch = (state=[], action) => {
    switch(action.type) {
        case LOGOUT_ACTION:
            return action.data;
        default:
            return state
    }
}

export default logoutFetch;