import React, { useEffect } from 'react';
import { useLocation, useHistory, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {  environment as configs } from '../../../environment';
import { loginActionApi, authenticateApi } from '../../../actions';
import { Images as img } from '../../../assets/Images';

import './style.scss';

function Landing() {
    const location = useLocation();
    const history = useHistory();
    const loginDispatch = useDispatch();
    const authDispatch = useDispatch();
    
    useEffect(() => {
        location.search.includes('?state') ? getToken(location.search) : window.location.href = configs.loginUrl;
    }, []);

    // set localStorage
    const setStorage = (response) => {
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('refresh_token', response.data.refresh_token);
        localStorage.setItem('sessionid', response.data.sessionid);
    }

    // login API call
    const getToken = (id) => {
        const params = new URLSearchParams(id);
        const code = params.get('code');
        const loginResponse = loginDispatch(loginActionApi(loginRequest(code)));
        loginResponse.then( async res => {
            const response = await res?.data?.data;
           if (res?.data?.status === 200) {
               setStorage(response);
               getAuthenticate();
           } else {

           }
        }, err => {

        })
    }

    // req body for login
    const loginRequest = (code) => {
        return {
            auth_code: code,
            redirect_uri: configs.redirectUri
        };
    }

    // authenticate method
    const getAuthenticate = () => {
        const header = {'access_token': localStorage.getItem('access_token'), 'sessionid': localStorage.getItem('sessionid')}
        const authResponse = authDispatch(authenticateApi(header));
        authResponse.then( async res => {
            const response = await res?.auth?.data;
            if (res?.auth?.status === 200) {
                history.push({pathname:'/dashboard', state: {roles: response.roles}});
            } 
        }, err => {

        })
    }
    
    return (
        <>
            <section className="landing-page">
                <img src={img.reportLanding} alt="placeholder"/> 
            </section>
        </>
    )
}
export default withRouter(Landing);
