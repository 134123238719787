import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { HttpLink } from 'apollo-link-http';
import { ApolloProvider } from '@apollo/react-hooks';
import { defineCustomElements } from "@latitudenpm/ezee-component/loader";
import {  environment as configs } from './environment';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './scss/index.scss';

// axios default configuration
axios.defaults.baseURL = configs.baseUrl;
axios.defaults.headers.common['Content-Type'] = 'application/json';

// axios request interceptors
axios.interceptors.request.use((request) => {
  return request;
});

// axios response interceptors
axios.interceptors.response.use((response) => {
  return response;
});

// 
const cache = new InMemoryCache();
const link = new HttpLink({
  uri: 'https://graphqlzero.almansi.me/api'
})

const client = new ApolloClient({
  cache,
  link
})

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
defineCustomElements(window);
