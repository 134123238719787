import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

export default function Button(props) {
    const { handleClick,id, label, primary, secondary, disabled, full, small, icon, space } = props;
    return (
        <>
            <button onClick={()=>handleClick()} id={id} className={classNames({"button": true, "button--primary": primary, "button--secondary": secondary, "button--disabled": disabled, "button--full": full, "button--small": small, "button--space": space})}>{icon && <samp dangerouslySetInnerHTML={{__html: icon}} />}{label}</button>
        </>
    );
}

Button.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    disabled: PropTypes.bool,
    small: PropTypes.bool,
    full: PropTypes.bool,
    space: PropTypes.bool
}

Button.defaultProps = {
    label: "Submit",
    primary: false,
    secondary: false,
    disabled: false,
    small: false,
    full: false,
    space: false
}