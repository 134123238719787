export const nav = [
    {
        name: 'Apps',
        url: '/',
        icon: '<i class="fa fa-th" aria-hidden="true"></i>',
        root: false
    },
    {
        name: 'Dashboard',
        url: '/dashboard',
        icon: '<i class="fa fa-tachometer" aria-hidden="true"></i>',
        root: true
    },
    {
        name: 'Reports',
        url: '/listing',
        icon: '<i class="fa fa-compass" aria-hidden="true"></i>',
        root: true
    },
    {
        name: 'Logout',
        url: '',
        icon: '<i class="fa fa-power-off" aria-hidden="true"></i>',
        root: false
    },
]

export const colors = ['#ff7777', '#91a6ea', '#e3b47d', '#7bdd92', '#6fbad6', '#ea8888', '#ea8888', '#b391ea', '#69a2f3', '#7bdd92'];

export const agentCallSummary=[
    {
        entity_name:"testSMS",
        login_date_time: "04-08-2020 06:05 AM",
        agent_name:"David",
        campaign_name:"Early Stage A - H,Early Stage A - H,Early Stage A - H",
        logged_on_time:"0:00",
        no_of_acc_actioned:"1",
    },
    {
        entity_name:"testSMS",
        login_date_time: "04-08-2020 06:05 AM",
        agent_name:"David",
        campaign_name:"Early Stage A - H,Early Stage A - H,Early Stage A - H",
        logged_on_time:"0:00",
        no_of_acc_actioned:"1",
    },
    {
        entity_name:"testSMS",
        login_date_time: "04-08-2020 06:05 AM",
        agent_name:"David",
        campaign_name:"Early Stage A - H,Early Stage A - H,Early Stage A - H",
        logged_on_time:"0:00",
        no_of_acc_actioned:"1",
    },
    {
        entity_name:"testSMS",
        login_date_time: "04-08-2020 06:05 AM",
        agent_name:"David",
        campaign_name:"Early Stage A - H,Early Stage A - H,Early Stage A - H",
        logged_on_time:"0:00",
        no_of_acc_actioned:"1",
    },
    {
        entity_name:"testSMS",
        login_date_time: "04-08-2020 06:05 AM",
        agent_name:"David",
        campaign_name:"Early Stage A - H,Early Stage A - H,Early Stage A - H",
        logged_on_time:"0:00",
        no_of_acc_actioned:"1",
    },
    {
        entity_name:"testSMS",
        login_date_time: "04-08-2020 06:05 AM",
        agent_name:"David",
        campaign_name:"Early Stage A - H,Early Stage A - H,Early Stage A - H",
        logged_on_time:"0:00",
        no_of_acc_actioned:"1",
    }
];

export const filterArray = ["Sort By Ascending", "Sort By Descending", "Sort Group by", "Show Count"];

export const rpc = [{
    name: "RPC",
    category: "application",
    description: "RPC summary report",
    deleted: false,
    version: "v1.0",
    mode: "DRAFT",
    productCode: "INTERAL_1qeaut",
    filters: {
      criteria: [
        {
          field: "oaoapplicants.actionHistory._contactStatus",
          operand: "EQUAL",
          value: "true"
        }
      ]
    },
    columns: [
      {
        label: "Entity Name",
        category: "application",
        field_key: "oaoapplicants.product_type_code",
        data_type: "String",
        user_data_type: "String",
        groupBy: true,
        sort: [
          {
            name: "Ascending",
            order: 1
          },
          {
            name: "Descending",
            order: -1
          }
        ],
        filterBy: true,
        aggregation: []
      },
      {
        label: "Date",
        category: "application",
        field_key: "oaoapplicants.actionHistory.takenOn",
        data_type: "Date",
        user_data_type: "String",
        groupBy: true,
        sort: [
          {
            name: "Ascending",
            order: 1
          },
          {
            name: "Descending",
            order: -1
          }
        ],
        filterBy: true,
        aggregation: [
          
        ]
      },
      {
        label: "Time",
        category: "application",
        field_key: "oaoapplicants.actionHistory.takenOn",
        data_type: "Date",
        user_data_type: "Time",
        groupBy: true,
        sort: [
          {
            name: "Ascending",
            order: 1
          },
          {
            name: "Descending",
            order: -1
          }
        ],
        filterBy: true,
        aggregation: [
          
        ]
      },
      {
        label: "Agent Name",
        category: "application",
        field_key: "oaoapplicants.actionHistory.takenby.username",
        data_type: "String",
        user_data_type: "String",
        groupBy: true,
        sort: [
          {
            name: "Ascending",
            order: 1
          },
          {
            name: "Descending",
            order: -1
          }
        ],
        filterBy: true,
        aggregation: [
          
        ]
      },
      {
        label: "Account Number",
        category: "application",
        field_key: "oaoapplicants.actionHistory.am_accountNo",
        data_type: "String",
        user_data_type: "String",
        groupBy: false,
        sort: [
          {
            name: "Ascending",
            order: 1
          },
          {
            name: "Descending",
            order: -1
          }
        ],
        filterBy: true,
        aggregation: []
      },
      {
        label: "Campiagn Name",
        category: "application",
        field_key: "oaoapplicants.actionHistory.currentStepName",
        data_type: "String",
        user_data_type: "String",
        groupBy: true,
        sort: [
          {
            name: "Ascending",
            order: 1
          },
          {
            name: "Descending",
            order: -1
          }
        ],
        filterBy: true,
        aggregation: []
      },
      {
        label: "Action Taken",
        category: "application",
        field_key: "oaoapplicants.actionHistory.amtp_actionTaken",
        data_type: "String",
        user_data_type: "String",
        groupBy: true,
        sort: [
          {
            name: "Ascending",
            order: 1
          },
          {
            name: "Descending",
            order: -1
          }
        ],
        filterBy: true,
        aggregation: []
      },
      {
        label: "DPD",
        category: "application",
        field_key: "oaoapplicants.actionHistory.advArrDays",
        data_type: "String",
        user_data_type: "Integer",
        groupBy: true,
        sort: [
          {
            name: "Ascending",
            order: 1
          },
          {
            name: "Descending",
            order: -1
          }
        ],
        filterBy: true,
        aggregation: []
      },
      {
        label: "Contact Through",
        category: "application",
        field_key: "oaoapplicants.actionHistory.amtp_contactThrough",
        data_type: "String",
        user_data_type: "String",
        groupBy: true,
        sort: [
          {
            name: "Ascending",
            order: 1
          },
          {
            name: "Descending",
            order: -1
          }
        ],
        filterBy: true,
        aggregation: []
      },
      {
        label: "Occurence",
        category: "application",
        field_key: "oaoapplicants.actionHistory.amtp_details.amtp_arrrangementOccurances",
        data_type: "String",
        user_data_type: "Integer",
        groupBy: true,
        sort: [
          {
            name: "Ascending",
            order: 1
          },
          {
            name: "Descending",
            order: -1
          }
        ],
        filterBy: true,
        aggregation: []
      },
      {
        label: "Amount",
        category: "application",
        field_key: "oaoapplicants.actionHistory.amtp_details.amtp_partPayment.amtp_amount",
        data_type: "String",
        user_data_type: "Integer",
        groupBy: true,
        sort: [
          {
            name: "Ascending",
            order: 1
          },
          {
            name: "Descending",
            order: -1
          }
        ],
        filterBy: true,
        aggregation: []
      },
      {
        label: "Payment Type",
        category: "application",
        field_key: "oaoapplicants.actionHistory.amtp_details.amtp_paymentMode",
        data_type: "String",
        user_data_type: "String",
        groupBy: true,
        sort: [
          {
            name: "Ascending",
            order: 1
          },
          {
            name: "Descending",
            order: -1
          }
        ],
        filterBy: true,
        aggregation: [
          
        ]
      },
      {
        label: "Payment Date",
        category: "application",
        field_key: "oaoapplicants.actionHistory.amtp_details.amtp_partPayment.amtp_date",
        data_type: "String",
        user_data_type: "Date",
        groupBy: true,
        sort: [
          {
            name: "Ascending",
            order: 1
          },
          {
            name: "Descending",
            order: -1
          }
        ],
        filterBy: true,
        aggregation: [
          
        ]
      },
      {
        label: "Payment Frequency",
        category: "application",
        field_key: "oaoapplicants.actionHistory.amtp_details.amtp_frequency",
        data_type: "String",
        user_data_type: "Integer",
        groupBy: true,
        sort: [
          {
            name: "Ascending",
            order: 1
          },
          {
            name: "Descending",
            order: -1
          }
        ],
        filterBy: true,
        aggregation: []
      },
      {
        label: "Notes",
        category: "application",
        field_key: "oaoapplicants.actionHistory.amtp_comments",
        data_type: "String",
        user_data_type: "String",
        groupBy: true,
        sort: [
          {
            name: "Ascending",
            order: 1
          },
          {
            name: "Descending",
            order: -1
          }
        ],
        filterBy: true,
        aggregation: []
      },
      {
        label: "Followup Date",
        category: "application",
        field_key: "oaoapplicants.actionHistory.amtp_details.amtp_partPayment.amtp_followupDate",
        data_type: "Date",
        user_data_type: "Date",
        groupBy: true,
        sort: [
          {
            name: "Ascending",
            order: 1
          },
          {
            name: "Descending",
            order: -1
          }
        ],
        filterBy: true,
        aggregation: [
          
        ]
      }
    ],
    pagination: {
      perPage: 10
    },
    defaultSort: {
      field: "oaoapplicants.actionHistory.takenOn",
      order: 1
    },
    assignedTeam: [
      {
        "hierarchy": {
          "allTeams": [
            "8ea25319-c079-44a9-8345-f7128845a57d",
            "a875f7c7-ec78-4b6a-b16f-e435ea45b279"
          ],
          userIds: [
            
          ]
        },
        "department": {
          "allTeams": [
            "f4e12d71-f6cd-4956-b9eb-f8350a62e3b8",
            "3344ed24-ed59-4482-a3fe-2634e8567363"
          ],
          userIds: [
            
          ]
        }
      }
    ],
    approval: {
      requestedBy: "",
      approvalStatus: "",
      assignedTo: [
        {
          id: "",
          username: "",
          displayName: ""
        }
      ],
      approvedTs: "",
      approvedBy: "",
      comments: ""
    },
    publishedTs: "",
    createdTs: "",
    modifiedTs: "",
    createdBy: "",
    updatedBy: "",
    deletedBy: "",
    deletedTs: ""
    
  }]





