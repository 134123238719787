import React from 'react';

import './style.scss';

function Header() {
    return (
        <header className="header">
            <h2>Analyze.Ezee</h2>
        </header>
    )
}

export default Header;
