import { combineReducers } from 'redux';

import userFetch from './userFetch';
import loginFetch from './loginFetch';
import authFetch from './authFetch';
import logoutFetch from './logoutFetch';
 
export const rootReducer = combineReducers({
    userFetch,
    loginFetch,
    authFetch,
    logoutFetch
})