import { LOGIN_ACTION } from '../constants';

const loginFetch = (state=[], action) => {
    switch(action.type) {
        case LOGIN_ACTION:
            return action.data;
        default:
            return state
    }
}

export default loginFetch;