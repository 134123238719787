import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Button, Search } from '../../generic';

import './style.scss';

function Listing() {
  const history = useHistory();
  const [showFilter, setShowFilter] = useState(false);
  const [tableData, setHeader] = useState();
  const [reportName, setReportName] = useState();

  useEffect(() => {
    setHeader(history.location.state.data);
    setReportName(history.location.state.name)
  }, [history])

  const reportsTable = <>
    <Table data={tableData} />
  </>

  return (
    <>
      <div className="list-row">
        <div className="list-row__col"><h2>{reportName}</h2></div>
        <div className="list-row__col">
          <div className="list-row__colin">
            <Search />
          </div>
          <div className="list-row__colin">
            <Button handleClick={() => { setShowFilter(!showFilter) }} id="filter" label="Filter" primary space icon="<i class='fa fa-filter' aria-hidden='true'></i>" />
            <Button id="download" label="Download" secondary icon="<i class='fa fa-download' aria-hidden='true'></i>" />
          </div>
        </div>
      </div>
      {reportsTable}
    </>

  );
}

export default Listing;
