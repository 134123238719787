import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { map } from 'lodash';
import {  environment as configs } from '../../../environment';

import { nav } from '../../../config';
import { logoutActionApi } from '../../../actions';
import './style.scss';

function SideBar() {
    const history = useHistory();
    const LogoutDispatch = useDispatch();

    function handleNav(el){
        el.root ? history.push(el.url) : slideNave(el);
    }

    const slideNave = (el) => {
        if (el.name === 'Logout') {
            const headers = {'refresh_token': localStorage.getItem('refresh_token')}
            const logOutResponse = LogoutDispatch(logoutActionApi(headers));
            logOutResponse.then(async res => {
                const response = await res?.data?.data;
               if (response.code === 200) {
                    localStorage.clear();
                    window.location.href = configs.loginUrl;
               } else {
                    // error should handle
               }
            }, err => {
                // error should handle
            })
        } else {
            // side menu code
        }
    }

    const navList = map(nav, (el, i) => {
        return <li key={i} onClick={(e) => handleNav(el)}>
            <samp dangerouslySetInnerHTML={{__html: el.icon}} />
            <span>{el.name}</span>
        </li>;
    })
    return (
        <aside className="side-bar">
            <ul>
                {navList}
            </ul>
        </aside>
    )
}

export default SideBar;
