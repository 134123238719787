import { GET_USER } from '../constants';

const getUser = (state=[], action) => {
    switch(action.type) {
        case GET_USER:
            return action.user;
        default:
            return state
    }
}

export default getUser;