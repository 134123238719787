import React, { useState, useEffect, useContext} from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';

import { Q_GET_USER_DETAILS } from '../../../graphql-query';
import { colors, rpc } from '../../../config';

import { userFetch } from '../../../actions';
import { LoaderContext } from '../../../providers/index';
import './style.scss';

function DashBoard() {
    const loaderData = useContext(LoaderContext);
    const userDispatch = useDispatch();
    const history = useHistory();
    // const user = useSelector( state => state.userfetchAction);
    const [view, setView] = useState(false);
    const { data } = useQuery(Q_GET_USER_DETAILS);

    const changeLoader = (value) => {
        loaderData.setLoaderData({
            loading:value
        });
    }
    
    useEffect(() => {
        changeLoader(false);
        if (data) userDispatch(userFetch(data));
    }, [data, userDispatch]);

    const handleView = () => setView(!view);

    const width = (size) => view ? '100' : size;

    const listwidth = (size) => view ? size : '100';

    function handleListView(el){
        history.push({pathname : '/listing', state: { data:el.columns, name: el.name }});
    }

    const list = map(rpc, (el, i) => {
        return <section key={i}>
            <div className="dashboard-wrap__list">
                <h3>{el.name} <span onClick={() => handleListView(el)}>View all</span></h3>
            </div>
            <ezee-row display>
                {map(el.report_details, (elm, i) => (
                    
                        <ezee-report-list colwidth={listwidth('33.33')} key={i} onClick={() => handleListView()} >
                            <ezee-col colwidth={view ? '10' : '3'} aligned="center">
                                <ezee-label bgcolorlist={colors[i]} label={el.report_name}></ezee-label>
                            </ezee-col>
                            <ezee-col colwidth={width('37')} aligned="left" fluid={view}>
                                <h5>{el.report_name}</h5>
                                <h6>{elm.description}</h6>
                                {view && <ezee-version color="#fd8b00" bcolor="#ffe0b2" version={elm.version} fixed></ezee-version>}
                            </ezee-col>
                            {!view && <ezee-col colwidth={width('30')} aligned="center" fluid={view}>
                                <ezee-version color="#fd8b00" bcolor="#ffe0b2" version={elm.version}></ezee-version>
                            </ezee-col>}
                            <ezee-col colwidth={width('30')} aligned="right" fluid={view}><p style={{paddingLeft: view ? '10%' : '0%'}}>{elm.date}</p></ezee-col>
                        </ezee-report-list>
                ))}
            </ezee-row>
        </section>
    })

    return (
        <>
            <div className="dashboard-header">
                <div className="dashboard-header__col">
                    <h2>Reports</h2>
                </div>
                <div className="dashboard-header__col">
                    <div className="dashboard-header__right">
                        <span className={view ? 'active': 'disable'}><i className="fa fa-list" onClick={handleView} aria-hidden="true"></i></span>
                        <span className={!view ? 'active': 'disable'}><i className="fa fa-th-large" aria-hidden="true" onClick={handleView}></i></span>
                    </div>
                </div>
            </div>
            <div className="dashboard-wrap">
                {list}
           </div>
        </>
    )
}

export default withRouter(DashBoard);
