import React, { useContext} from 'react';
import {LoaderContext} from '../../../providers';

function Loader() {
    const loaderContext = useContext(LoaderContext);
    const { loaderData } = loaderContext;
    const { loading } = loaderData;

    return <ezee-loader loading={loading}></ezee-loader>
}

export default Loader;



