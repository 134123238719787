import React, { useState } from 'react';
import { map } from 'lodash';
import { filterArray } from '../../../config';

import './style.scss';

function TableComponent(props) {

    const [currentTh, setCurrentTh] = useState(null);

    function tableFilter(index){
        setCurrentTh(index)
    }
    
    const header = map(props.data, (el, i) => {
        return <th key={i}>
            <div className="table-row__title">
                <span onClick={() => tableFilter(i)}>{el.label}</span>
                <div className={["table-row__dropdown", currentTh === i ? 'table-row__dropdown--active' : 'table-row__dropdown--hide'].join(' ')}>
                    <ul>
                        {map(filterArray, (o, s) => 
                            <li key={s}><label htmlFor={s}><input id={s} type="radio" name={el.label} />{o}</label></li>
                        )}
                    </ul>
                </div>
            </div>
        </th>
    });

    const tdata = map(props.data, (el, i) => {
        return <tr key={i}>
            {map(props.header, (elm, inx) =>
                <td key={inx}>{el[elm.key]}</td> 
            )} 
        </tr>
    });

    return (
        <div className="table-row">
            <table className="table-row__table">
                <tbody>
                    <tr>{header}</tr>
                    {tdata}
                </tbody>
            </table>
        </div>
    )
}

export default TableComponent
