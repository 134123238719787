import { AUTHENTICATE_ACTION } from '../constants';

const authFetch = (state=[], action) => {
    switch(action.type) {
        case AUTHENTICATE_ACTION:
            return action;
        default:
            return state
    }
}

export default authFetch;