import React, { useContext } from 'react';
import {ToasterContext} from '../../../providers';

function Toaster() {
    const toasterContext = useContext(ToasterContext);
    const { toasterData } = toasterContext;
    const {showAlert,header,message}=toasterData;

    return (
        <ezee-fixed hide={!showAlert} top={true}  open>
            <ezee-alert-message center={true} error={false} success={true} name={header} content={message}></ezee-alert-message>
      </ezee-fixed>
    );
}

export default Toaster;