import React from 'react';
import { Header, SideBar } from '../HOC';

import './style.scss';

 function LayoutComponent({ children }) {
    return (
        <>
            <section className="layout-wrap">
                <Header />
                <SideBar />
                <div className="layout-wrap__container">
                    {children}
                </div>
            </section>
        </>
    )
}

export default LayoutComponent;
