import  { useState } from 'react';
import { LoaderContext, ToasterContext } from '../../src/providers';

const ContextProvider=({ children })=>{
    const [loaderData,setLoaderData] = useState({
        loading:false
        });
    const [toasterData,setToasterData] = useState({
        showAlert:false,
        header:'success',
        message:'success'
    });

    return (
        <LoaderContext.Provider value={{loaderData,setLoaderData}} >
            <ToasterContext.Provider value={{toasterData,setToasterData}}>
                {children}
            </ToasterContext.Provider>
        </LoaderContext.Provider>
    )
}

  export default ContextProvider;